@use 'sass:map';
@use '@angular/material' as mat;
@import '../typography.scss';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');

  html, body {
    color: mat.get-color-from-palette($foreground-palette, 'text'); // (light) #707070, (dark) ...
  }

  h1, h2, h3, h4, h5, h6,
  *[class^='mat-headline-'], .vehicle-name {
    color: mat.get-color-from-palette($foreground-palette, 'header-text') !important;
  }

  // h1, .mat-headline-1 {
  //   color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  //   line-height: 1.2;
  // }
  // h2, .mat-headline-2 {
  //   color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  // }
  // h3, h4, .mat-headline-3, .mat-headline-4 {
  //   color: mat.get-color-from-palette($accent-palette, 'default'); // (light) #143987, (dark) ...
  // }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active{
      -webkit-background-clip: text;
      -webkit-text-fill-color: mat.get-color-from-palette($foreground-palette, 'autofill');
      transition: background-color 5000s ease-in-out 0s;
  }

  .th-border-color {
    border-color: mat.get-color-from-palette($foreground-palette, dividers);
  }
  .th-border-color-light {
    border-color: mat.get-color-from-palette($foreground-palette, dividers-light);
  }

  .th-color-text {
    color: mat.get-color-from-palette($foreground-palette, 'text');
  }
  .th-color-secondary-text {
    color: mat.get-color-from-palette($foreground-palette, 'secondary-text');
  }
  .th-color-accent-text {
    color: mat.get-color-from-palette($foreground-palette, 'accent-text');
  }

  .th-background-card {
    background-color: mat.get-color-from-palette($background-palette, 'card');
  }

  .th-background-card-accent {
    background-color: mat.get-color-from-palette($background-palette, 'card-accent');
  }

  .th-color-primary {
    color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  }
  .th-background-primary {
    background-color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  }
  .th-border-primary {
    border-top-color: mat.get-color-from-palette($primary-palette, 'default') !important; // (light) #67bfaf, (dark) ...
    border-right-color: mat.get-color-from-palette($primary-palette, 'default') !important; // (light) #67bfaf, (dark) ...
    border-bottom-color: mat.get-color-from-palette($primary-palette, 'default') !important; // (light) #67bfaf, (dark) ...
    border-left-color: mat.get-color-from-palette($primary-palette, 'default') !important; // (light) #67bfaf, (dark) ...
  }

  .th-color-accent {
    color: mat.get-color-from-palette($accent-palette, 'default'); // (light) #143987, (dark) ...
  }
  .th-background-accent {
    background-color: mat.get-color-from-palette($accent-palette, 'default'); // (light) #143987, (dark) ...
  }

  .th-color-warn {
    color: mat.get-color-from-palette($warn-palette, 'default'); // (light) #de4d4d, (dark) ...
  }
  .th-background-warn {
    background-color: mat.get-color-from-palette($warn-palette, 'default'); // (light) #de4d4d, (dark) ...
  }

  .number-headline-1 {
    color: mat.get-color-from-palette($accent-palette, '900-contrast')
  }

  .soc-status {
    color: mat.get-color-from-palette($primary-palette, 'default'); // (light) #67bfaf, (dark) ...
  }

  a {
    color: inherit;

    &:hover {
      filter: brightness(80%);
    }
  }

  .caption {
    font-weight: 100;

    color: mat.get-color-from-palette($foreground-palette, 'caption-text');

    &.secondary {
      color: mat.get-color-from-palette($foreground-palette, 'secondary-text');
    }
  }

  .gm-style .gm-style-iw-c {
    & > button {
      & span {
        background-color: mat.get-color-from-palette($primary-palette, 'main-contrast') !important;
      }
    }
  }

  .loading-shimmer, .shimmer {
    background: #444;
    background: linear-gradient(
      to right,
      mat.get-color-from-palette($background-palette, 'background') 8%,
      mat.get-color-from-palette($background-palette, 'app-bar') 18%,
      mat.get-color-from-palette($background-palette, 'background') 33%
    );
    background-size: 1200px 100%;
    color: transparent;
  }

  /* -------------- Google Maps Elements --------------- */

  // ? do this to change GM built-in map controls & pray that they never change the layout
  .gm-bundled-control > .gmnoprint > div, .gm-control-active, .gm-svpc {
    background-color: mat.get-color-from-palette($background-palette, card) !important;

    div {
      background-color: mat.get-color-from-palette($foreground-palette, divider) !important;
    }
  }

  .gm-style-mtc * {
    background-color: mat.get-color-from-palette($background-palette, card) !important;
    color: mat.get-color-from-palette($foreground-palette, text) !important;
  }
}
