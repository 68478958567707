@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');
  $primary-palette: map.get($color-config, 'primary');

  .behaviour-gauge-section{
    &.gauge-aggressive {
      background-color: mat.get-color-from-palette($foreground-palette, 'aggressive');
    }

    &.gauge-average {
      background-color: mat.get-color-from-palette($foreground-palette, 'average-gauge');
    }

    &.gauge-calm {
      background-color: mat.get-color-from-palette($foreground-palette, 'calm-gauge');
    }
  }
}
