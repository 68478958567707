@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');

  .personal-info-wrapper {
    mat-icon.unverified-email {
      color: mat.get-color-from-palette($warn-palette);
    }
  }
}
