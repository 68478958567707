@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .content {
    .item {
      color: mat.get-color-from-palette($foreground-palette, 'checkmark');
    }
  }
  .members-tile {
    padding-bottom: 1px;
  }

  .search-container {
    input::placeholder {
      color: white
    }
  }
}
