/**
 * This file contains custom styles for ng-bootstrap elements.
 * Primarily theme integration with Material Themes
 */

@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .popover {
    // --bs-popover-db: mat.get-color-from-palette($background-palette, 'card');
    background-color: mat.get-color-from-palette($background-palette, 'action-card') !important;
    --bs-popover-max-width: 23rem !important;
  }

  .popover-body {
    color: mat.get-color-from-palette($foreground-palette, 'text');
  }
}
