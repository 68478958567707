@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');
  $warn-palette: map.get($color-config, 'warn');

  .support-modal-wrapper {
    .fields-wrapper {
      .info {
        color: mat.get-color-from-palette($foreground-palette, 'secondary-text');
      }
    }

    .bottom-wrapper {
      .error-text {
        color: mat.get-color-from-palette($warn-palette);
      }
    }
  }
}
