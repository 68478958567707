@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $warn: map.get($color-config, 'warn');
  $foreground: map.get($color-config, 'foreground');
  $background: map.get($color-config,'background');

  .query-control {
    .datepicker-container {
      background: mat.get-color-from-palette($background, 'accent-bg');
    }
    mat-form-field.datepicker-container.mat-focused button.mdc-icon-button {
      color: mat.get-color-from-palette($primary);
    }

    mat-form-field.datepicker-container.mat-form-field-invalid:not(.mat-focused) button.mdc-icon-button {
      color: mat.get-color-from-palette($warn);
    }

    .vehicles-preview {
      .info-badge {
        background-color: mat.get-color-from-palette($foreground, text);
        color: invert(mat.get-color-from-palette($foreground, text));
        outline: solid 2px mat.get-color-from-palette($background, main-background);
      }
    }

    .dates-preview {
      background-color: rgba(mat.get-color-from-palette($background, card), 0.6);

      border-style: solid;
      border-width: 1px;
      border-color: mat.get-color-from-palette($foreground, dividers);
    }

    .search-container {
      input {
        background: mat.get-color-from-palette($background, 'search-bar');
        border: none;
      }
    }
  }

}
