@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background: map.get($color-config, 'background');
  $foreground: map.get($color-config, 'foreground');
  $primary: map.get($color-config, 'primary');

  app-header {
    background-color: rgba(mat.get-color-from-palette($background, 'main-background'), 0.6);
    backdrop-filter: blur(10px);
  }

  .zeva-header {
    background: transparent;
    color: mat.get-color-from-palette($foreground, text);

    .account-menu button {
      color: mat.get-color-from-palette($foreground, 'text');
      background-color: mat.get-color-from-palette($background, 'action-card');
    }

    .sidenav-trigger {
      border-color: rgba(mat.get-color-from-palette($foreground, 'divider'), 0.35);
    }
  }

  .announcement {
    border-color: mat.get-color-from-palette($primary);
    background-color: rgba(mat.get-color-from-palette($primary), 0.35);
  }
}
