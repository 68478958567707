@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary: map.get($color-config, 'primary');
  $foreground: map.get($color-config, 'foreground');
  $background: map.get($color-config, 'background');

  .mat-drawer {
    background-color: mat.get-color-from-palette($background, 'accent-bg');
    box-shadow: none;
  }

  .sidenav-drawer {
    background-color: mat.get-color-from-palette($background, 'accent-bg');
    border-right-color: mat.get-color-from-palette($background, 'accent-bg') !important;
  }

  .drawer-boundary {
    background-color: mat.get-color-from-palette($background, 'accent-bg');
    border-color: lighten(mat.get-color-from-palette($foreground, 'divider'), 15%);

    button {
      background-color: mat.get-color-from-palette($background, 'accent-bg');
      border-color: lighten(mat.get-color-from-palette($foreground, 'divider'), 15%);
      color: #fff;
    }
  }

  .sidenav-container .menu-holder {
    // .mat-expansion-panel-body {
    //   background-color: darken(mat.get-color-from-palette($background, 'accent-bg'), 4%);
    // }

    .menu-item {
      background-color: transparent;

      .link {
        color: mat.get-color-from-palette($foreground, 'accent-text');

        .mat-expansion-indicator:after {
          color: mat.get-color-from-palette($foreground, 'accent-text');
        }

        &:hover {
          background-color: mat.get-color-from-palette($background, 'accent-bg') !important;
          color: lighten(mat.get-color-from-palette($primary), 15%);
        }

        // &.mat-expanded {
        //   background-color: darken(mat.get-color-from-palette($background, 'accent-bg'), 4%) !important;
        // }
      }

      &.active .link {
        color: mat.get-color-from-palette($primary);

        .mat-expansion-indicator:after {
          color: mat.get-color-from-palette($primary);
        }
      }
    }
  }

  .smaller-links {
    color: mat.get-color-from-palette($foreground, accent-text-dark);
  }
}
