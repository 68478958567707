:root {
  /* Material Button overrides */
  --mdc-outlined-button-outline-width: 1px;
  --mdc-typography-button-font-size: 14px;
  --mdc-chip-elevated-container-color: transparent;
  --mdc-typography-button-letter-spacing: normal;
}

.mat-ripple {
  display: none;
}

//* ----------------- mat-button

@include mat.button-density(0);

// // TODO: Theme this
// .mdc-button:disabled {
//   background: transparent !important;
//   color: #999EB450 !important;
//   border-color: #999EB450;
//   border-width: var(--mdc-outlined-button-outline-width, 1px);
//   border-style: solid;
// }

//* ----------------- mat-form-field

// overriding default density behaviour for mat-form-fields
@include mat.form-field-density(-2);

.mat-mdc-form-field {
  @include mat.icon-button-density(-4);

  &.narrow {
    @include mat.form-field-density(-5);

    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-suffix {
      padding: 6px 10px;

      .mat-icon,
      .mat-mdc-icon-button {
        padding: 0;
      }

      .mat-mdc-icon-button {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.large {
    @include mat.form-field-density(0);
    font-size: 1rem;
  }
}

.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--focused .mdc-notched-outline__trailing {
	border-width: 1px !important;
}

.mdc-text-field--outlined .mdc-notched-outline {
  z-index: 0 !important;
}

.mat-mdc-form-field-infix {
  display: flex;
  align-items: center;
}

*[matSuffix], *[matPrefix] {
  position: relative;
}

//* ----------------- mat-chips

.mat-mdc-chip {
  height: 2rem !important;
}

.mdc-evolution-chip-set__chips {
  display: flex;
  justify-content: flex-start;
  width: calc(100% + 8px);
}

.mdc-evolution-chip, .mdc-evolution-chip__cell, .mdc-evolution-chip__action {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mdc-evolution-chip-set__chips > .mat-mdc-chip-option {
  min-width: 5rem;
  text-align: center;
  display: flex;
  justify-content: center;

  &:not(.mat-mdc-chip-selected) {
    background: none !important;
    border: solid 1px;
  }

  & .mat-mdc-chip-graphic {
    width: 0 !important;
    opacity: 0 !important;
  }
}

// * ---------------- Button Toggle Group

.zeva-button-toggle-alternative {
  @include mat.button-toggle-density(-3);

  border: none !important;

  .mat-button-toggle {
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
  }
}

.card-header .mat-button-toggle-group {
  position: relative;
  border-radius: 6px;

  .mat-button-toggle {
    min-width: 150px;
    border-radius: 0 !important;
    padding: 0.2rem 0;
    margin-left: 0;

    &:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    &:last-child {
      border-radius: 0 5px 5px 0 !important;
    }
  }
}

// * ---------------- Slide Toggle

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  border: solid 1px;
  padding: 0.5rem;
  border-radius: 100px;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: calc(100% - var(--mdc-switch-handle-width, 1.125rem)) !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: var(--mdc-switch-handle-width, 0.6rem) !important;
  height: var(--mdc-switch-handle-height, 0.6rem) !important;
  border-radius: var(--mdc-switch-handle-shape, 100px) !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  border-radius: var(--mdc-switch-track-shape, 2rem) !important;

  &::before, &::after {
    display: none !important;
  }
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__icons,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__shadow {
  display: none;
}

// * ---------------- mat-checkbox, mat-pseudo-checkbox
// NOTE: Use .mat-original-checkbox to make a checkbox use original material checkbox margins

.mat-mdc-checkbox:not(.mat-original-checkbox) {
  line-height: 1 !important;

  .mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none !important;
  }

  .mat-mdc-checkbox .mdc-checkbox {
    padding: 0 !important;
    margin: 0 !important;
  }

  .mat-mdc-checkbox .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
  }

  .mat-mdc-checkbox .mdc-checkbox > input {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    width: 18px !important;
    height: 18px !important;
  }

  .mat-mdc-checkbox-touch-target {
    width: 110%;
    height: 110%;
  }
}

.mat-mdc-checkbox .mdc-checkbox__background,
.mat-pseudo-checkbox-full {
  border-width: 1px !important;
}

map-layers .mat-mdc-checkbox, .mat-mdc-checkbox.mat-original-checkbox {
  --mdc-checkbox-state-layer-size: 40px;

  .mdc-checkbox {
    padding: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2) !important;
    margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2) !important;
  }

  .mdc-checkbox__background {
    top: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2);
  }
}

// * ---------------- mat-table

.mat-mdc-table .mat-mdc-row {
  min-height: 52px !important;
  height: fit-content !important;

  .mat-mdc-cell {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
