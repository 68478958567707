@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .climate-keeper {
    .mat-button-toggle-appearance-standard {
      border-color: mat.get-color-from-palette($foreground-palette, 'control-icon-border') !important;
    }
  }
}
