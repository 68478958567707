@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');
  $background: map.get($color-config, 'background');

  .faq-container {
    box-shadow: 0 0 1px 1px mat.get-color-from-palette($background, 'box-shadow');
  }

}
