/*
  This file contains Angular Material style overrides for the components
  that could not have been styled via mixins or other conventional methods.

  Override Material styling only as a last resort
  as intruding into material styles can cause unexpected behaviours

  Consult these tomes for guidance:
    - https://indepth.dev/tutorials/angular/angular-material-theming-system-complete-guide
    - https://m2.material.io/design/layout/applying-density.html#usage
*/

@use 'sass:map';
@use '@angular/material' as mat;

@import '../values.scss';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  // $accent-palette: map.get($color-config, 'accent');
  // $warn-palette: map.get($color-config, 'warn');
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');

  //* ================ mat-form-field ================ */

  .country-list-button {
    color: mat.get-color-from-palette($foreground-palette, text) !important;
  }

  :not(.mat-form-field-invalid *) {
    &.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: mat.get-color-from-palette($foreground-palette, 'divider');
      }
    }

    &.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: mat.get-color-from-palette($foreground-palette, 'focused-outline') !important;
      }
    }

    &.mdc-text-field:not(.mdc-text-field--focused):not(.mdc-text-field--disabled)
      .mdc-floating-label {
      color: mat.get-color-from-palette($foreground-palette, 'text') !important;
    }

    &.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
      color: #ff653e !important;
    }

    .mat-mdc-form-field-infix {
      color: mat.get-color-from-palette($foreground-palette, 'text') !important;
    }
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    background-color: mat.get-color-from-palette($background-palette, 'card') !important;
  }
  .mat-mdc-form-field.th-blend-accent {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      background-color: mat.get-color-from-palette($background-palette, 'accent-bg-sub') !important;
      border-color: rgba(
        mat.get-color-from-palette($foreground-palette, 'divider'),
        0.35
      ) !important;
    }
  }
  .mat-mdc-form-field.th-blend-accent:not(.mat-form-field-invalid) {
    .mdc-text-field:not(.mdc-text-field--focused):not(.mdc-text-field--disabled)
      .mdc-floating-label {
      color: mat.get-color-from-palette($foreground-palette, 'accent-text') !important;
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix,
    .mat-datepicker-toggle {
      color: mat.get-color-from-palette($foreground-palette, 'accent-text') !important;
    }

    .mat-mdc-form-field-infix {
      color: mat.get-color-from-palette($foreground-palette, 'accent-text') !important;
    }
  }

  .mat-mdc-form-field .mdc-text-field__input::placeholder {
    color: mat.get-color-from-palette($foreground-palette, 'secondary-text') !important;
  }

  .mat-form-field-disabled {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: #999eb450;
    }

    .mat-mdc-select-value,
    .mat-mdc-select-arrow {
      color: #999eb450 !important;
    }
  }

  //* ================ mat-select, mat-option, mat-checkbox, mat-pseudo-checkbox ================ */

  .mat-mdc-select-value,
  .mat-mdc-select-panel {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }
  .mat-mdc-form-field.th-blend-accent {
    .mat-mdc-select-value,
    .mat-mdc-select-panel {
      color: mat.get-color-from-palette($foreground-palette, 'accent-text') !important;
    }
  }

  .mat-mdc-select-arrow {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }
  .mat-mdc-form-field.th-blend-accent {
    .mat-mdc-select-arrow {
      color: mat.get-color-from-palette($foreground-palette, 'accent-text') !important;
    }
  }

  // TODO: Those two can possibly be styled properly, not sure :/
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__checkmark,
  .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__mixedmark {
    color: mat.get-color-from-palette($primary-palette, '300-contrast') !important;
    border-color: mat.get-color-from-palette($primary-palette, '300-contrast') !important;
  }

  .mat-mdc-select-panel .mdc-list-item__primary-text,
  .mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }
  .mat-mdc-form-field.th-blend-accent {
    .mat-mdc-select-panel .mdc-list-item__primary-text,
    .mat-mdc-option.mdc-list-item:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
      .mdc-list-item__primary-text {
      color: mat.get-color-from-palette($foreground-palette, 'accent-text') !important;
    }
  }

  .mat-mdc-checkbox
    .mdc-checkbox
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate='true']
    )
    ~ .mdc-checkbox__background,
  .mat-pseudo-checkbox-full:not(.mat-pseudo-checkbox-checked) {
    border-color: mat.get-color-from-palette($foreground-palette, 'divider') !important;
  }

  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
    background: #ff653e !important;
  }

  .mat-mdc-checkbox
    .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate='true']
    )
    ~ .mdc-checkbox__background,
  .mat-mdc-checkbox
    .mdc-checkbox:focus
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
      [data-indeterminate='true']
    )
    ~ .mdc-checkbox__background {
    border-color: mat.get-color-from-palette($foreground-palette, 'focused-outline') !important;
  }

  .mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-icon-color: #ff653e !important;
    --mdc-checkbox-selected-hover-icon-color: #ff653e !important;
    --mdc-checkbox-selected-focus-icon-color: #ff653e !important;
    --mdc-checkbox-selected-pressed-icon-color: #ff653e !important;
  }

  //* ================ mat-button ================ */

  // Overriding accent button text color, due to Material's persistent pick of black when palette hue is lower than 500
  // see https://m2.material.io/design/color/the-color-system.html#color-theme-creation
  .mat-mdc-unelevated-button.mat-primary:not(:disabled) {
    color: #fff;
  }
  .mat-mdc-outlined-button:not(:disabled) {
    --mdc-outlined-button-outline-color: mat.get-color-from-palette($primary-palette, 'default');
  }

  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-flat-button {
    margin: 0;
  }

  //* ================ mat-chip, mat-chip-option ================*/

  .mdc-evolution-chip-set__chips > .mat-mdc-chip-option:not(.mat-mdc-chip-selected) {
    border-color: mat.get-color-from-palette($foreground-palette, 'divider');
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__icon--trailing,
  .mat-mdc-chip-option:not(.mdc-evolution-chip--disabled):not(.mat-mdc-chip-selected)
    .mdc-evolution-chip__text-label {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }

  // TODO: Move this to a dedicated mat-table wrapper component when implemented (shared/table/ - but with some refactoring)
  //* ================ mat-table ================ */

  .mat-mdc-row,
  .mat-mdc-footer-row {
    &.selected,
    &.selected:hover {
      background-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.05);
    }
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    color: mat.get-color-from-palette($foreground-palette, 'text');
    border-bottom-color: mat.get-color-from-palette($foreground-palette, 'divider');
  }

  .mat-mdc-table {
    background-color: var(--mat-table-background-color) !important
  }

  .mat-mdc-paginator {
    color: var(--mat-paginator-container-text-color) !important;
    background-color: var(--mat-paginator-container-background-color) !important;
  }

  //* ================ Button Toggle Group ================ */

  .mat-button-toggle-checked {
    background-color: #ff653e !important;
    border-color: #ff653e !important;
    color: mat.get-color-from-palette($primary-palette, '300-contrast') !important;
  }

  .mat-button-toggle-checked.mat-button-toggle-disabled {
    opacity: 0.5;
  }

  // Alternative style for the button toggle group

  .zeva-button-toggle-alternative {
    --mat-standard-button-toggle-hover-state-layer-opacity: 0;

    .mat-button-toggle {
      &:not(.mat-button-toggle-checked) {
        border-color: transparent;
      }

      &:not(.mat-button-toggle-disabled):not(.mat-button-toggle-checked):hover,
      &:not(.mat-button-toggle-disabled):not(.mat-button-toggle-checked):focus {
        border-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.4) !important;
      }

      &.mat-button-toggle-checked {
        background-color: mat.get-color-from-palette($background-palette, 'card') !important;
        border-color: mat.get-color-from-palette($primary-palette, 'default') !important;
        color: mat.get-color-from-palette($primary-palette, 'default') !important;
      }
    }
  }

  //* ================ Slide Toggle ================ */

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    border-color: mat.get-color-from-palette($foreground-palette, 'dividers');
  }

  .mat-mdc-slide-toggle .mdc-switch:disabled .mdc-switch__track {
   opacity: 0.4;
  }
  //* ================ Mat Slider ================ */

  .mat-mdc-slider .mdc-slider__track--inactive {
    background-color: mat.get-color-from-palette($background-palette, 'unselected-chip');
    opacity: 1;
  }

  //* =============== Mat Radio Box ============= */

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #ff653e !important;
    --mdc-radio-selected-hover-icon-color: #ff653e !important;
    --mdc-radio-selected-icon-color: #ff653e !important;
    --mdc-radio-selected-pressed-icon-color: #ff653e !important;
    --mat-mdc-radio-checked-ripple-color: #ff653e !important;
  }


  .mat-mdc-slide-toggle {
    // orange handle
    --mdc-switch-selected-handle-color: #ff653e !important;
    --mdc-switch-selected-hover-handle-color: #ff461c !important;
    --mdc-switch-selected-focus-handle-color: #ff653e !important;
    --mdc-switch-selected-pressed-handle-color: #ff461c !important;
    --mdc-switch-unselected-focus-handle-color: #616161 !important;
    --mdc-switch-unselected-hover-handle-color: #616161 !important;
    --mdc-switch-unselected-pressed-handle-color: #616161 !important;
    --mdc-switch-disabled-selected-handle-color: #616161 !important;
    --mdc-switch-disabled-unselected-handle-color: #616161 !important;
    --mdc-switch-disabled-selected-track-color: #616161 !important;
    --mdc-switch-disabled-unselected-track-color: #616161 !important;
    --mdc-switch-unselected-focus-state-layer-color: #616161 !important;
    --mdc-switch-unselected-pressed-state-layer-color: #616161 !important;
    --mdc-switch-unselected-hover-state-layer-color: #616161 !important;
  }


  scrollbar-color: mat.get-color-from-palette($background-palette, 'box-shadow')
    mat.get-color-from-palette($background-palette, 'card');


  //* ================ mat-snackbar ================ */

  .mat-mdc-snack-bar-container .mdc-snackbar__surface {
    background-color: mat.get-theme-color($theme, background, action-card) !important;
  }

  .mat-mdc-snack-bar-container .mdc-snackbar__label {
    color: mat.get-theme-color($theme, foreground, text) !important;
  }

  // custom mat-snack-bar for error messages
  // add `panelClass: 'mat-custom-snackbar__error'` to your snackbar to color it
  .mat-custom-snackbar__error {
    .mat-mdc-snack-bar-label {
      color: #fff !important;
    }

    .mdc-snackbar__surface {
      border: 2px solid mat.get-color-from-palette($primary-palette, 'default');
      background-color: mix(
          mat.get-color-from-palette($background-palette, 'card'),
          mat.get-color-from-palette($primary-palette, 'default'),
          80%
        ) !important;
    }
  }

  //* ================ mat-dialog ================ */

  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: mat.get-theme-color($theme, background, card) !important;
  }

  .mat-dialog-backdrop-transparent {
    background: none !important;
  }
}
