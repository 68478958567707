@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, background);
  $button-color: mat.get-color-from-palette($background-palette, 'tesla-button');
  $button-text: mat.get-color-from-palette($foreground-palette, 'tesla-text');

  .auth-container {
    background: #191919;//rgb(255,255,255);
    background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 60%, rgba(242,243,248,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 60%, rgba(242,243,248,1) 100%);
    background: mat.get-color-from-palette($background-palette, 'background-gradient');

    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#f2f3f8",GradientType=1);

    .tesla-sso {
      background: $button-color;
      color: $button-text !important;
    }
    .footer {
      color: mat.get-color-from-palette($foreground-palette, 'footer-text');
    }
    .checkbox-container {
      &.th-background-card {
        border-color: mat.get-color-from-palette($foreground-palette, 'divider');
      }
    }
  }
}
