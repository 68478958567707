@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');

  .driver-status {
    .item {
      color: mat.get-color-from-palette($background-palette, 'card');
    }
  }
}
