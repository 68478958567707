@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .charge-icon {
    color: mat.get-color-from-palette($foreground-palette, 'control-icon');
  }

  .schedule-icon {
    color: mat.get-color-from-palette($foreground-palette, 'control-icon');
  }

  .manual-icon {
    &.disabled {
      color: mat.get-color-from-palette($foreground-palette, 'control-icon') !important;
    }
  }
}
