@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground: map.get($color-config, 'foreground');
  $accent: map.get($color-config, 'accent');

  .reset-done-wrapper .email-instructions {
    color: mat.get-color-from-palette($foreground, 'secondary-text');
  }

  .reset-done-wrapper .reset-title mat-icon {
    color: mat.get-color-from-palette($accent);
  }
}
