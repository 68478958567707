@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .carbon-reduction-top-widget {
    background-color: rgba(mat.get-color-from-palette($background-palette, 'card'), 0.6);

    div {
      background-color: transparent
    }
  }
}
