@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .autocomplete-container .autocomplete-item:hover {
    background-color: mat.get-color-from-palette($accent-palette, 'default');
  }

  .map-button-container {
    background-color: mat.get-color-from-palette($background-palette, 'map-overlay') !important;
    box-shadow: 0px 1px 4px -1px mat.get-color-from-palette($background-palette, 'main-background') !important;
    button {
      background-color: mat.get-color-from-palette($background-palette, 'map-overlay') !important;
    }
  }

  .map-layers {
    background-color: mat.get-color-from-palette($background-palette, 'map-overlay');
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .map-search-bar {
    background-color: mat.get-color-from-palette($background-palette, 'map-overlay') !important;

    input {
      color: mat.get-color-from-palette($foreground-palette, 'map-text');
      background-color: mat.get-color-from-palette($background-palette, 'map-overlay');
    }
    input::placeholder {
      color: mat.get-color-from-palette($foreground-palette, 'map-text');
    }
  }

  .nav-card,
  .weather-card {
    background-color: mat.get-color-from-palette($background-palette, 'map-overlay');
  }

  .disabled-container {
    background-color: mat.get-color-from-palette($background-palette, 'map-overlay');
  }

  .gm-style-iw {
    background-color: mat.get-color-from-palette($background-palette, 'card');
    scrollbar-color: mat.get-color-from-palette($foreground-palette, 'map-text')
      mat.get-color-from-palette($background-palette, 'card');
    color: mat.get-color-from-palette($foreground-palette, 'map-text');
  }
  .gm-style-iw-d {
    background-color: mat.get-color-from-palette($background-palette, 'card');
    scrollbar-color: mat.get-color-from-palette($foreground-palette, 'map-text')
      mat.get-color-from-palette($background-palette, 'card');
  }
  .gm-style-iw-tc::after {
    background-color: mat.get-color-from-palette($background-palette, 'card');
  }
  .gm-ui-hover-effect > span {
    background-color: mat.get-color-from-palette($background-palette, 'close-button');
  }
  .street-view-error {
    background-color: mat.get-color-from-palette($background-palette, 'card');
  }
}
