@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');
  $background: darken(mat.get-color-from-palette($background-palette, 'card'), 1.5%);

  .datetime-overlay {
    background: $background;
  }

  .vehicle-trip {
    background-color: $background;

    &:hover {
      background-color: darken($background, 1.5%);
    }
  }

  .selected-trip {
    color: mat.get-color-from-palette($foreground-palette, 'header-text') !important;
  }
}
