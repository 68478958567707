@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $foreground: map.get($color-config, 'foreground');

  .play-settings mat-button-toggle {
      border-top-color: mat.get-color-from-palette($foreground, 'text') !important;
      border-bottom-color: mat.get-color-from-palette($foreground, 'text') !important;
      border-left-color: mat.get-color-from-palette($foreground, 'text') !important;
      border-right-color: mat.get-color-from-palette($foreground, 'text') !important;
  }

  .play-settings  .mat-button-toggle-appearance-standard:not(.mat-button-toggle-disabled):not(.mat-button-toggle-checked):hover {
    border-top-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.4) !important;
    border-bottom-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.4) !important;
    border-left-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.4) !important;
    border-right-color: rgba(mat.get-color-from-palette($primary-palette, 'default'), 0.4) !important;
  }

  .play-settings .mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary-palette, 'default') !important;
    border-top-color: mat.get-color-from-palette($primary-palette, 'default') !important;
    border-bottom-color: mat.get-color-from-palette($primary-palette, 'default') !important;
    border-left-color: mat.get-color-from-palette($primary-palette, 'default') !important;
    border-right-color: mat.get-color-from-palette($primary-palette, 'default') !important;
    color: mat.get-color-from-palette($primary-palette, '300-contrast') !important;
  }

  .vehicle-trip-timeline {
    .heading {
      .aggressive {
        color: mat.get-color-from-palette($foreground, 'aggressive');
      }

      .average {
        color: mat.get-color-from-palette($foreground, 'average');
      }

      .calm {
        color: mat.get-color-from-palette($foreground, 'calm');
      }
    }
  }
}
