@use 'sass:map';
@use '@angular/material' as mat;

@import '../../../../styles/values.scss';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');

  .supercharger-amenities {
    .amenities {
      .amenity {
        margin-right: 0.5rem;
        color: mat.get-color-from-palette($accent-palette, 200);

        &:nth-child(2n) {
          color: mat.get-color-from-palette($accent-palette, 600);
        }

        &:nth-child(3n) {
          color: mat.get-color-from-palette($accent-palette, 900);
        }
      }
    }
  }
}
