@use '@angular/material' as mat;
@use 'sass:map';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

@use './misc-theme.scss' as misc;
@use './material-overrides-theme.scss' as material-override;
@use '../bootstrap.scss' as bootstrap;
@use '../../app/core/home/home-theme.component.scss' as home;
@use '../../app/core/sidenav/sidenav-theme.component.scss' as sidenav;
@use '../../app/auth/auth-theme.component.scss' as auth;
@use '../../app/auth/pages/select-subscription/select-subscription-theme.scss' as select-subscription;
@use '../../app/core/card-grid/components/card/card-theme.component.scss' as card;
@use '../../app/core/header/header-theme.component.scss' as header;
@use '../../app/shared/table/table-theme.component.scss' as table;
@use '../../app/shared/progress-bar/progress-bar-theme.component.scss' as progress-bar;
@use '../../app/shared/mat-extended/datetime-picker/time-picker/time-picker-theme.component.scss' as time-picker;
@use '../../app/monitoring/pages/live/components/live-query-list/live-query-list-theme.component.scss' as live-query-list;
@use '../../app/shared/mat-extended/datetime-picker/datetime-compare-picker/datetime-compare-picker-theme.component.scss' as compare-picker;
@use '../../app/shared/color-bubble/color-bubble-theme.component.scss' as color-bubble;
@use '../../app/auth/pages/forgot-password/forgot-password-theme.component.scss' as forgot-password;
@use '../../app/auth/pages/forgot-password/reset-password-theme.component.scss' as reset-password;
@use '../../app/admin/pages/profile/personal-info/personal-info-theme.component.scss' as personal-info;
@use '../../app/admin/pages/manage-subscription/manage-subscription-theme.component.scss' as manage-subscription;
@use '../../app/core/support/support-modal-theme.component.scss' as support;
@use '../../app/auth/pages/forgot-password/done-reset-theme.component.scss' as done-reset;
@use '../../app/analytics/components/analytics-query-tool/analytics-query-tool-theme.component.scss' as analytics;
@use '../../app/insights/pages/main/components/carbon-reduction/carbon-reduction-theme.component.scss' as carbon-reduction;
@use '../../app/monitoring/pages/energy/components/battery-rating/battery-rating-theme.component.scss' as battery-rating;
@use '../../app/monitoring/pages/energy/components/charge-locations/charge-location-list-theme.component.scss' as charge-locations;
@use '../../app/monitoring/pages/energy/components/destinations/destination-list-theme.component.scss' as destinations;
@use '../../app/monitoring/pages/live/components/live-vehicle-view/live-vehicle-view-theme.component.scss' as live-vehicle-view;
@use '../../app/monitoring/pages/replay/replay-theme.component.scss' as replay;
@use '../../app/monitoring/pages/replay/components/replay-trip-description/replay-trip-description-theme.component.scss' as replay-trip-description;
@use '../../app/monitoring/pages/live/components/live-vehicle-behaviour-score/live-vehicle-behavior-score-theme.component.scss' as live-vehicle-behavior-score;
@use '../../app/shared/superchargers/supercharger-info-window/supercharger-info-window-template.component.scss' as supercharger-info-window;
@use '../../app/shared/map/map-theme.component.scss' as shared-map;
@use '../../app/analytics/components/usage-analytics/usage-analytics-heatmap/usage-analytics-heatmap-theme.component.scss' as usage-analytics-heatmap;
@use '../../app/monitoring/pages/replay/components/replay-timeline/replay-timeline-theme.component.scss' as replay-timeline;
@use '../../app/adhoc/faq/faq-theme.component.scss' as faq;
@use '../../app/adhoc/generic-error/generic-error-theme.component.scss' as generic-error;
@use '../../app/auth/pages/subscribe/subscribe-theme.component.scss' as subscribe;
@use '../../app/monitoring/pages/control/components/driver-control/driver-control-theme.component.scss' as driver-control;
@use '../../app/monitoring/pages/control/components/charging-management/charging-management-theme.component.scss' as charging-management;
@use '../../app/monitoring/pages/control/components/climate-control/climate-control-theme.component.scss' as climate-control;
@use '../../app/auth/pages/connect-vehicles/connect-vehicle-theme.component.scss' as connect-vehicle;
@use '../../app/admin/pages/profile/profile-theme.component.scss' as profile;
@use '../../app/admin/pages/organization/components/users/users-theme.component.scss' as users;
@use '../../app/core/feedback/feedback-modal-theme.component.scss' as feedback-modal;
@use '../../app/admin/pages/organization/components/groups-and-permissions/groups-and-permissions-theme.component.scss' as groups-and-permissions;
@use '../../app/shared/vehicle/vehicle-showcase/vehicle-showcase-theme.component.scss' as vehicle-showcase;
@use '../../app/monitoring/pages/control/components/security-control/security-control-theme.component.scss' as security-control;

@include mat.core();

@import '../values.scss';

/* Importing Bootstrap SCSS files. */
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
// This import will provide set typography settings and override Bootstrap's Typography
@import '../typography.scss';

@import 'bootstrap/scss/bootstrap';

/* Theme Declaration */

// Theme Palettes
@import './theme-light.scss';
@import './theme-dark.scss';

// Theme Definitions
// Light
$theme-light: (
  color: (
    primary: map.get($palette-light, 'primary'),
    accent: map.get($palette-light, 'accent'),
    warn: map.get($palette-light, 'warn'),
    foreground: $mat-foreground-light,
    background: $mat-background-light,
  ),
  density: 0,

  is-dark: false,
);
// Dark
$theme-dark: (
  color: (
    primary: map.get($palette-dark, 'primary'),
    accent: map.get($palette-dark, 'accent'),
    warn: map.get($palette-dark, 'warn'),
    foreground: $mat-foreground-dark,
    background: $mat-background-dark,
  ),
  density: 0,

  is-dark: true,
);

/* Theme Init */

@include mat.all-component-typographies($typography-config);

.theme--light {
  @include mat.all-component-themes($theme-light);

  @include bootstrap.theme($theme-light);
  @include material-override.theme($theme-light);

  @include misc.theme($theme-light);
  @include home.theme($theme-light);
  @include sidenav.theme($theme-light);
  @include auth.theme($theme-light);
  @include select-subscription.theme($theme-light);
  @include header.theme($theme-light);
  @include card.theme($theme-light);
  @include table.theme($theme-light);
  @include progress-bar.theme($theme-light);
  @include time-picker.theme($theme-light);
  @include live-query-list.theme($theme-light);
  @include compare-picker.theme($theme-light);
  @include color-bubble.theme($theme-light);
  @include forgot-password.theme($theme-light);
  @include reset-password.theme($theme-light);
  @include personal-info.theme($theme-light);
  @include support.theme($theme-light);
  @include done-reset.theme($theme-light);
  @include analytics.theme($theme-light);
  @include carbon-reduction.theme($theme-light);
  @include battery-rating.theme($theme-light);
  @include charge-locations.theme($theme-light);
  @include destinations.theme($theme-light);
  @include live-vehicle-view.theme($theme-light);
  @include supercharger-info-window.theme($theme-light);
  @include shared-map.theme($theme-light);
  @include usage-analytics-heatmap.theme($theme-light);
  @include replay-timeline.theme($theme-light);
  @include replay.theme($theme-light);
  @include faq.theme($theme-light);
  @include manage-subscription.theme($theme-light);
  @include generic-error.theme($theme-light);
  @include replay-trip-description.theme($theme-light);
  @include live-vehicle-behavior-score.theme($theme-light);
  @include subscribe.theme($theme-light);
  @include driver-control.theme($theme-light);
  @include charging-management.theme($theme-light);
  @include climate-control.theme($theme-light);
  @include connect-vehicle.theme($theme-light);
  @include profile.theme($theme-light);
  @include users.theme($theme-light);
  @include feedback-modal.theme($theme-light);
  @include groups-and-permissions.theme($theme-light);
  @include vehicle-showcase.theme($theme-light);
  @include security-control.theme($theme-light);
}

.theme--dark {
  @include mat.all-component-colors($theme-dark);

  @include bootstrap.theme($theme-dark);
  @include material-override.theme($theme-dark);

  @include misc.theme($theme-dark);
  @include home.theme($theme-dark);
  @include sidenav.theme($theme-dark);
  @include auth.theme($theme-dark);
  @include select-subscription.theme($theme-dark);
  @include header.theme($theme-dark);
  @include card.theme($theme-dark);
  @include table.theme($theme-dark);
  @include progress-bar.theme($theme-dark);
  @include time-picker.theme($theme-dark);
  @include live-query-list.theme($theme-dark);
  @include compare-picker.theme($theme-dark);
  @include color-bubble.theme($theme-dark);
  @include forgot-password.theme($theme-dark);
  @include reset-password.theme($theme-dark);
  @include personal-info.theme($theme-dark);
  @include support.theme($theme-dark);
  @include done-reset.theme($theme-dark);
  @include analytics.theme($theme-dark);
  @include carbon-reduction.theme($theme-dark);
  @include battery-rating.theme($theme-dark);
  @include charge-locations.theme($theme-dark);
  @include destinations.theme($theme-dark);
  @include live-vehicle-view.theme($theme-dark);
  @include supercharger-info-window.theme($theme-dark);
  @include shared-map.theme($theme-dark);
  @include usage-analytics-heatmap.theme($theme-dark);
  @include replay-timeline.theme($theme-dark);
  @include replay.theme($theme-dark);
  @include faq.theme($theme-dark);
  @include manage-subscription.theme($theme-dark);
  @include generic-error.theme($theme-dark);
  @include replay-trip-description.theme($theme-dark);
  @include live-vehicle-behavior-score.theme($theme-dark);
  @include subscribe.theme($theme-dark);
  @include driver-control.theme($theme-dark);
  @include charging-management.theme($theme-dark);
  @include climate-control.theme($theme-dark);
  @include connect-vehicle.theme($theme-dark);
  @include profile.theme($theme-dark);
  @include users.theme($theme-dark);
  @include feedback-modal.theme($theme-dark);
  @include groups-and-permissions.theme($theme-dark);
  @include vehicle-showcase.theme($theme-dark);
  @include security-control.theme($theme-dark);
}
