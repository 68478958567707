@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');
  $foreground: map.get($color-config, 'foreground');
  $background: map.get($color-config, 'background');

  .subscribe-wrapper {
    color: mat.get-color-from-palette($foreground, 'base');
  }
  .card-container {
    background-color: mat.get-color-from-palette($background, 'card');
    color: mat.get-color-from-palette($foreground, 'base') !important;
  }
}
