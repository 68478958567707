@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');
  $warn-palette: map.get($color-config, 'warn');

  .feedback-modal-wrapper {
    background-color: mat.get-color-from-palette($background-palette, 'card');
    .fields-wrapper {
      .info {
        color: mat.get-color-from-palette($foreground-palette, 'secondary-text');
      }
    }
    .close {
      color: mat.get-color-from-palette($foreground-palette, 'text');
    }

    //
    //  EVERYTHING BELOW HERE WAS COMMENTED OUT.
    //  THIS FILE WAS NOT CONNECTED TO THE THEME UNTIL NOW, SO THESE LINES WERE NOT IN USE
    //
    // .bottom-wrapper {
    //   .error-text {
    //     color: mat.get-color-from-palette($warn-palette);
    //   }
    // }
  }
}
