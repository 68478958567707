@use 'sass:map';
@use '@angular/material' as mat;

@import '../../../../../styles/values.scss';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  // $accent-palette: map.get($color-config, 'accent');
  // $primary-palette: map.get($color-config, 'primary');
  $background: map.get($color-config, 'background');

  .usage-heatmap-map-layers {
    background-color: mat.get-color-from-palette($background, 'map-overlay');
  }
  .main-box {
    background-color: mat.get-color-from-palette($background, 'map-overlay') !important;
  }
  .search-bar {
    background-color: mat.get-color-from-palette($background, 'map-overlay') !important;
  }
}
