@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .vehicle-trip {
    .details{
      .trip-score {
        &.aggressive {
          color: mat.get-color-from-palette($foreground-palette, 'aggressive');
        }

        &.average {
          color: mat.get-color-from-palette($foreground-palette, 'average');
        }

        &.calm {
          color: mat.get-color-from-palette($foreground-palette, 'calm');
        }
      }
    }
  }
}
