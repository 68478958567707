@use 'sass:map';
@use '@angular/material' as mat;

@import '../../../../../../styles/values.scss';

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map-get($color-config, 'accent');
  $warn-palette: map-get($color-config, 'warn');
  $primary-palette: map.get($color-config, 'primary');
  $background: map.get($color-config, 'background');

  .data-points .data-point {
    &:nth-child(2n) {
      .colorful {
        background-color: mat.get-color-from-palette($primary-palette, 600);
      }
    }

    &:nth-child(3n) {
      .colorful {
        background-color: mat.get-color-from-palette($primary-palette, 900);
      }
    }
  }

  .progress-icons {
    &.eff {
      color: mat.get-color-from-palette($accent-palette, '900-contrast');
    }
    &.capacity {
      color: mat.get-color-from-palette($primary-palette, 400);
    }
    &.charge {
      color: mat.get-color-from-palette($warn-palette, 400);
    }
  }

  .data-points .data-point .colorful {
    border-radius: $border-radius;
    background-color: mat.get-color-from-palette($primary-palette, 200);

    &.lightest {
      background-color: mat.get-color-from-palette($accent-palette, 200) !important;
    }

    &.light {
      background-color: mat.get-color-from-palette($accent-palette, 600) !important;
    }

    &.dark {
      background-color: mat.get-color-from-palette($accent-palette, 900) !important;
    }
  }
  .range-bar-container {
    background: mat.get-color-from-palette($background, 'disabled-slider');
  }
  .range-bar {
    background-color: mat.get-color-from-palette($accent-palette);
  }

  .slider-container {
    ::ng-deep {
      .mdc-slider__track--active_fill {
        border: 3px solid transparent;
      }

      .mat-mdc-slider .mdc-slider__track--inactive {
        background: linear-gradient(to right, green 0%, orange 50%, red 100%);
      }
    }

    &.disabled {
      ::ng-deep {
        .mat-mdc-slider .mdc-slider__track--inactive {
          background: mat.get-color-from-palette($background, 'disabled-slider') !important;
        }
      }
      mat-slider ::ng-deep mat-slider-visual-thumb {
        display: none;
      }
    }
  }
}
