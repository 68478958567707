@use '@angular/material' as mat;

// Fonts & Typography
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300..700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

@import './values.scss';

$font-family-base: $std-font-family;
$font-size-base: 14px;
$line-height-base: 1;

$typography-config: mat.define-typography-config(
  $font-family: $font-family-base,
  $headline-1: mat.define-typography-level(1.953rem, 1.953rem, 400, $letter-spacing: -0.0134em),
  $headline-2: mat.define-typography-level(1.72rem, 1.72rem, 400, $letter-spacing: -0.0089em),
  $headline-3: mat.define-typography-level(1.5rem, 1.5rem, 400, $letter-spacing: 0.0000em),
  $headline-4: mat.define-typography-level(1.36rem, 1.36rem, 400, $letter-spacing: 0.0074em),
  $headline-5: mat.define-typography-level(1.25rem, 1.25rem, 400, $letter-spacing: 0.0000em),
  $headline-6: mat.define-typography-level(1rem, 1rem, 400, $letter-spacing: 0.0075em),
  $subtitle-1: mat.define-typography-level(1rem, 1.5rem, 300, $letter-spacing: 0.0094em),
  $subtitle-2: mat.define-typography-level(0.9375rem, 1.25rem, 300, $letter-spacing: 0.0067em),
  $body-1: mat.define-typography-level(1rem, 1.25rem, 300, $letter-spacing: 0.0179em),
  $body-2: mat.define-typography-level(1rem, 1.25rem, 300, $letter-spacing: 0.0179em),
  $caption: mat.define-typography-level(0.875rem, 1.25rem, 300, $letter-spacing: 0.0333em),
  $button: mat.define-typography-level(1rem, 1rem, 300, $letter-spacing: 0.0179em),
  /* $input: mat.define-typography-level(inherit, 1.125, 400, $letter-spacing: 1.5px) */
);

.number-headline-1 {
  font-size: 1.6rem !important;
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: bold !important;

  .subscript {
    font-size: 1.125rem !important;
  }
}

.soc-status {
  font-family: 'Roboto Condensed', sans-serif !important;
}
