@use '@angular/material' as mat;

@use '../../../../../styles/values.scss' as v;

zeva-card, .zeva-card {
  --zeva-card-padding: 1.5rem;

  position: relative;
  display: block;

  padding: 0;
  overflow: visible;

  border-radius: v.$border-radius;

  // ------- header, body, footer -------

  .card-header, .card-footer {
    position: relative;
    display: block;

    padding: var(--zeva-card-padding);
  }

  .card-footer .mat-mdc-button-base:not(.mat-mdc-icon-button) {
    min-width: 8rem;
  }

  .card-body {
    transition: padding 0.15s v.$easing-fn 0s, margin 0.15s v.$easing-fn 0s;

    padding: 0 var(--zeva-card-padding);
    margin: var(--zeva-card-padding) 0;
    border-radius: v.$border-radius;

    &.no-margin {
      padding: 0rem !important;
      margin: 0rem !important;
    }
  }

  .card-header ~ .card-body,
  .card-body ~ .card-footer {
    padding-top: 0;
    margin-top: 0;
  }

  .action-footer, .action-header {
    @include mat.icon-button-density(-4);

    display: flex;
    flex-direction: row;
  }
  .action-header {
    justify-content: space-between;
    align-items: center;
  }
  .action-footer {
    justify-content: center;
    align-items: flex-end;

    &.justify-left {
      justify-content: flex-start;
    }
    &.justify-right {
      justify-content: flex-end;
    }
    &.justify-space-between {
      justify-content: space-between;
    }

    & > *:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .card-header {
    @include mat.button-toggle-density(-4);

    .item-group {
      display: flex;
      align-items: center;
    }
  }

  .card-body {
    height: 100%;
  }

  // tile config

  &.tile {
    height: 30rem;

    &.chart {
      height: 35rem;
    }

    display: flex;
    flex-direction: column;

    overflow-x: hidden;

    & > .card-body {
      flex-grow: 2;

      display: flex;
      flex-direction: column;
      align-items: normal;
      justify-content: flex-start;

      overflow-y: auto;
    }

    & > .card-header, & > .card-footer {
      flex-grow: 0;
    }
  }

  &.auto-height {
    height: auto;

    & > .card-body {
      height: auto;
      max-height: 100%;
      overflow-y: visible;
    }
  }

  &.ad-full {
    height: 180px;
  }

  &.ad-vdual {
    max-height: 600px;
  }

  &.card-fullscreen {
    height: calc(100vh - 8rem) !important;
  }

  &.collapsible {
    min-height: 0 !important;

    &.card-body {
      transition: max-height 0.15s v.$easing-fn 0s, margin 0.15s v.$easing-fn 0s;
      min-height: 0;
      max-height: 25rem;
    }

    &.collapsed > .card-body {
      max-height: 0;
      overflow-y: hidden;
      margin: 0;
    }
  }

  // ------- cosmetics -------

  // small
  &.chibi {
    --zeva-card-padding: 1rem;

    .card-footer .mat-mdc-button-base:not(.mat-mdc-icon-button) {
      min-width: auto;
    }
  }

  &.fill-height {
    height: 100%;

    & > .card-body {
      height: 100%;
      overflow-y: visible;
    }
  }

  &.outlined {
    border-style: solid;
    border-width: 1px;
  }

  &.transparent {
    background: none;
    border: none;
    box-shadow: none !important;
  }

  &.padded {
    padding: var(--zeva-card-padding);
  }

  &.no-padding {
    & > .card-header,
    & > .card-footer,
    & > .card-body {
      padding: 0;
      margin: 0;
    }

    & > .card-header > .mat-button-toggle-group {
      top: 0;
    }
  }

  &.faded {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

    -webkit-backdrop-filter: blur(7px);
    backdrop-filter: blur(7px);
  }

  .active-indicator {
    transition: opacity 0.1s ease 0s;
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    opacity: 0;
    border-radius: 0 0 v.$border-radius v.$border-radius;
  }

  &.selectable {
    cursor: pointer;

    & > .active-indicator {
      display: block;
    }

    &:not(.active):hover > .active-indicator {
      opacity: 0.5;
    }

    &.active > .active-indicator {
      opacity: 1;
    }
  }

  &.tabs {
    .card-body {
      margin-top: -0.5rem;
    }
  }
}
