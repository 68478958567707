@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $foreground-palette: map.get($color-config,'foreground');
  $primary-color: mat.get-color-from-palette($primary-palette);
  .datetime-picker-parent-container{
  .datetime-picker-container {
    .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
      background: mat.get-color-from-palette($primary-palette, 50);
    }

    .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
      background: $primary-color
    }
    .period-picker {
      mat-selection-list{
        mat-list-option[aria-disabled="false"]:hover{
             color: $primary-color
        }
        mat-list-option[aria-selected="true"]{
          color: $primary-color
        }
      }
    }
    .compare-picker-warning{
      border: 1px solid $primary-color;
      color: $primary-color
    }
  }
    .mat-mdc-slide-toggle {
      // orange handle
      --mdc-switch-selected-focus-state-layer-color: #{$primary-color};
      --mdc-switch-selected-handle-color: #{$primary-color};
      --mdc-switch-selected-hover-state-layer-color: #{$primary-color};
      --mdc-switch-selected-pressed-state-layer-color: #{$primary-color};
      --mdc-switch-selected-focus-handle-color: #{$primary-color};
      --mdc-switch-selected-hover-handle-color: #{$primary-color};
      --mdc-switch-selected-pressed-handle-color: #{$primary-color};
      --mdc-switch-disabled-selected-handle-color: #{$primary-color};
      --mdc-switch-disabled-unselected-handle-color: #{$primary-color};
      --mdc-switch-unselected-focus-state-layer-color: #{$primary-color};
      --mdc-switch-unselected-pressed-state-layer-color: #{$primary-color};
      --mdc-switch-unselected-hover-state-layer-color: #{$primary-color};
      --mdc-switch-unselected-focus-handle-color: #{$primary-color};
      --mdc-switch-unselected-hover-handle-color: #{$primary-color};
      --mdc-switch-unselected-pressed-handle-color: #{$primary-color};
      --mdc-switch-handle-surface-color: #{$primary-color};
      --mdc-switch-unselected-handle-color: #{$primary-color};
    }
  }
}
