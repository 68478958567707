@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');
  $primary-palette: map.get($color-config, 'primary');

  .vehicle-list .vehicle-filters .vehicle-state-filter:not(.active, .loading-shimmer) {
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
    background: transparent !important;
  }

  .state-icon-container.special-state .special-state-items .special-state-item {
    border-color: mat.get-color-from-palette($foreground-palette, 'divider') !important;

    span {
      color: mat.get-color-from-palette($foreground-palette, 'text');
    }
  }
}
