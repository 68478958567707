@use 'sass:map';
@use '@angular/material' as mat;

// TODO: Move this to global theme.scss, since this is now shared
@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .zeva-main-container .mat-drawer-inner-container {
    // background: mat.get-color-from-palette($background-palette, 'accent-bg') !important;
    color: mat.get-color-from-palette($foreground-palette, 'text') !important;
  }

  .zeva-main-container {
    background-color: mat.get-color-from-palette($background-palette, 'accent-bg');
  }

  .mat-sidenav-content.drawer-content {
    background-color: mat.get-color-from-palette($background-palette, 'main-background');
  }

  .footer {
    color: mat.get-color-from-palette($foreground-palette, 'footer-text');
  }
  .popover-arrow:after {
    border-bottom-color: mat.get-color-from-palette($background-palette, 'action-card');
  }

  .zebot-main-container {
    .mat-drawer {
      background-color: mat.get-color-from-palette($background-palette, 'background');
    }

    .drawer-boundary {
      background-color: mat.get-color-from-palette($background-palette, 'background');

      button {
        background-color: mat.get-color-from-palette($primary-palette);
      }
    }
  }
}
