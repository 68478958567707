@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');

  battery-rating .background {
    color: mat.get-color-from-palette($background-palette, 'unselected-chip');
  }
  .zeva-generic-table-legend {
    .mat-mdc-row:hover {
      background-color: mat.get-color-from-palette($background-palette, 'full-contrast') !important;
    }
  }
}
