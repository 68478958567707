@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  // .action-prompt {
  //   background-color: mat.get-theme-color($theme, $background, 'action-card') !important;
  // }

  zeva-card.locked_tile {
    background-color: mat.get-theme-color($theme, background, 'locked-tile');
  }

  zeva-card, .zeva-card {
    background-color: mat.get-theme-color($theme, background, card);
    color: mat.get-theme-color($theme, foreground, 'text');

    & .active-indicator {
      background-color: mat.get-theme-color($theme, accent, 'lighter');
    }

    .card-body {
      background-color: inherit;
    }

    &.tile:not(.transparent) {
      box-shadow: 0 2px 5px mat.get-theme-color($theme, background, 'card-shadow');

      border-style: solid;
      border-width: 1px;
      border-color: mat.get-theme-color($theme, background, card-bevel);
    }

    &.outlined {
      border-top-color: mat.get-theme-color($theme, foreground, 'divider');
      border-right-color: mat.get-theme-color($theme, foreground, 'divider');
      border-bottom-color: mat.get-theme-color($theme, foreground, 'divider');
      border-left-color: mat.get-theme-color($theme, foreground, 'divider');
    }

    &.color-warning {
      border-top-color: mat.get-theme-color($theme, warn, 'default');
      border-right-color: mat.get-theme-color($theme, warn, 'default');
      border-bottom-color: mat.get-theme-color($theme, warn, 'default');
      border-left-color: mat.get-theme-color($theme, warn, 'default');
      color: mat.get-theme-color($theme, warn, 'default');
    }

    &.elevated {
      box-shadow:
        mat.get-theme-color($theme, background, card-elevated-shadow-1) 0px 30px 60px -12px,
        mat.get-theme-color($theme, background, card-elevated-shadow-2) 0px 18px 36px -18px;
    }
  }
}
