@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);

  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  $warn-palette: map.get($color-config, 'warn');
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  shared-progress-bar {
    background-color: mat.get-color-from-palette($background-palette, 'unselected-chip');
  }

  shared-progress-bar .progress-indicator {
    background-color: rgba(125, 125, 125, 0.5);

    &.color-primary {
      background-color: mat.get-color-from-palette($accent-palette, 'default');

      &.negative {
        background-color: mat.get-color-from-palette($primary-palette, 'default');
      }
    }

    &.color-accent {
      background-color: mat.get-color-from-palette($accent-palette, '900-contrast');

      &.negative {
        background-color: mat.get-color-from-palette($accent-palette, 'default');
      }
    }

    &.color-warn {
      background-color: mat.get-color-from-palette($warn-palette, 'default');

      &.negative {
        background-color: mat.get-color-from-palette($accent-palette, 'default');
      }
    }
  }

  shared-progress-bar {
    &::before, &::after {
      background-color: mat.get-color-from-palette($foreground-palette, 'secondary-text');
    }
  }
}
