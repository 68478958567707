@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');
  $primary-palette: map.get($color-config, 'primary');
  $background: map.get($color-config, 'background');

  .error-box {
    background-color: mat.get-color-from-palette($background, 'main-background');
  }

}
