@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');

  .message-box {
    opacity: 60%;

    &.active {
      opacity: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -20px;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      border-color: mat.get-color-from-palette($primary-palette, 'default') transparent transparent
        transparent;
    }
  }

  td.active {
    color: mat.get-color-from-palette($primary-palette, 'default');
  }
}
