@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $warn-palette: map.get($color-config, 'warn');
  $foreground: map.get($color-config, 'foreground');

  .forgot-form-wrapper .email-instructions {
    color: mat.get-color-from-palette($foreground, 'base');
  }

  .forgot-form-wrapper .error-text {
    color: mat.get-color-from-palette($warn-palette);
  }
}
