@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $accent-palette: map.get($color-config, 'accent');

  destination-list .pages mat-icon {
    color: mat.get-color-from-palette($accent-palette);
  }

  destination-list .page:not(.selected) {
    border: 1px solid mat.get-color-from-palette($accent-palette);
    background-color: transparent;
  }

  destination-list .page.selected {
    border: none;
    background-color: mat.get-color-from-palette($accent-palette);
  }

  destination-list .stat-container > div > mat-icon {
    color: mat.get-color-from-palette($accent-palette);
  }

}
