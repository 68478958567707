@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme){
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, background);
  $button-color: mat.get-color-from-palette($background-palette, 'tesla-button');

  .plan-body{
    background-color: mat.get-color-from-palette($background-palette, 'manage-subscription');
  }

  .cancelling-message {
    background-color: mat.get-color-from-palette($foreground-palette, 'dividers');;
  }
}
