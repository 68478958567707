$border-radius: 5px;

$grid-gutter: 1.5rem;
$card-padding: 1.5rem;

// material design standard easign function
$easing-fn: cubic-bezier(0.4, 0.0, 0.2, 1);
$close-speed: 0.2s;
$open-speed: 0.25s;

// $std-font-family: 'Noto Sans', sans-serif;
$std-font-family: 'Jost', "Helvetica Neue", sans-serif;
$alt-font-family: 'Roboto Condensed', sans-serif !important;
// $std-font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$tile-takeover-height: calc(100vh - (var(--zeva-page-margin) * 2) - 5rem - 2px);
