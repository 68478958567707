@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .profile-roles-container{
    border: 1px mat.get-color-from-palette($foreground-palette, 'divider') solid;
  }
}
