@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');

  .connect-vehicles-form {
    .vehicle-box {
      .card-body {
        border: 1px solid mat.get-color-from-palette($foreground-palette, 'control-icon-border') !important;
      }
    }
    .vehicles-container {
      border: 1px solid mat.get-color-from-palette($foreground-palette, 'control-icon-border') !important;
    }
  }
}
