@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $background-palette: map.get($color-config, 'background');
  $foreground-palette: map.get($color-config, 'foreground');

  .vehicle-name {
    border-bottom-color: mat.get-color-from-palette($foreground-palette, 'divider');
    background-color: mat.get-color-from-palette($background-palette, 'card');
  }
}
