@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $color-config: mat.get-color-config($theme);
  $foreground-palette: map.get($color-config, 'foreground');
  $background-palette: map.get($color-config, 'background');
  $primary-palette: map.get($color-config, 'primary');

  .clock .tick {
    color: mat.get-color-from-palette($foreground-palette, 'text');
    background-color: mat.get-color-from-palette($background-palette, 'action-card');

    &.selected {
      color: mat.get-color-from-palette($background-palette, 'time-picker');
      background-color: mat.get-color-from-palette($primary-palette, 300);
    }
  }
}
